import { Button, CircularProgress, TextField } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import { updateAdminOrder } from '../../../helpers/api/order'
import { Order, OrderStatus } from '../../../helpers/types'
import { FormContainer } from './Styled'

interface StatusButtonProps {
  order: Order
  refetchOrder: () => void
  loading: boolean
  isHomeShipping: boolean
}

const StatusButton = (props: StatusButtonProps) => {
  const { order, refetchOrder, loading, isHomeShipping } = props
  const [trackCode, setTrackCode] = useState('')
  const showSnackbar = useAppContext()?.showSnackbar
  const { status, id, statusHistory, user } = order

  const handleChangeTrackCode = e => {
    setTrackCode(e.target.value)
  }

  const handleClick = async status => {
    try {
      await updateAdminOrder(id, {
        status,
        ...(status === OrderStatus.SHIPPING_PROCESS && {
          trackCode
        }),
        statusHistory: {
          ...statusHistory,
          [status]: {
            date: new Date(),
            status
          }
        }
      })
      showSnackbar('success', 'Commande mise à jour avec succès')
      refetchOrder()
      window.scrollTo(0, 0)
    } catch (e) {
      console.error(e)
      showSnackbar('error', 'Erreur lors de la mise a jour de la commande')
    }
    return null
  }

  if (status === OrderStatus.PAYMENT_ACCEPTED) {
    return (
      <Button
        disabled={loading}
        startIcon={loading && <CircularProgress />}
        color="primary"
        variant="contained"
        onClick={() => handleClick(OrderStatus.PROCESSING)}
      >
        Prochaine étape : En cours de traitement !
      </Button>
    )
  }
  if (status === OrderStatus.PROCESSING) {
    return (
      <FormContainer
        onSubmit={e => {
          e.preventDefault()
          handleClick(
            isHomeShipping
              ? OrderStatus.SHIPPING_PROCESS
              : OrderStatus.READY_TO_COLLECT
          )
        }}
      >
        {isHomeShipping ? (
          <TextField
            label="Numéro de suivi"
            variant="outlined"
            type="text"
            onChange={handleChangeTrackCode}
            value={trackCode}
            required
          />
        ) : (
          <div />
        )}
        <Button
          disabled={loading || (isHomeShipping && !trackCode)}
          startIcon={loading && <CircularProgress />}
          color="primary"
          variant="contained"
          type="submit"
        >
          {`Prochaine étape : ${
            isHomeShipping ? "En cours d'expédition" : 'Prêt â collecter'
          } !`}
        </Button>
      </FormContainer>
    )
  }
  if (status === OrderStatus.READY_TO_COLLECT) {
    return (
      <Button
        disabled={loading}
        startIcon={loading && <CircularProgress />}
        color="primary"
        variant="contained"
        onClick={() => handleClick(OrderStatus.COLLECTED)}
      >
        Prochaine étape : Collecté !
      </Button>
    )
  }
  if (status === OrderStatus.SHIPPING_PROCESS) {
    return (
      <Button
        disabled={loading}
        startIcon={loading && <CircularProgress />}
        color="primary"
        variant="contained"
        onClick={() => handleClick(OrderStatus.DELIVERED)}
      >
        Prochaine étape : Livré !
      </Button>
    )
  }
  return null
}

export default StatusButton
