import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Title = styled(Typography)`
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const ProductImage = styled('img')`
  border-radius: 5px;
  height: 80px;
`

export const OrderPrice = styled(Typography)`
  font-size: 16px;
`

export const TotalPrice = styled('div')`
  font-size: 20px;
  font-weight: 500;
`

export const AddressesContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  gap: 30px;
`

export const ButtonContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`

export const FormContainer = styled('form')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Container = styled('div')`
  margin: 15px 0;
`

export const DeliveryContainer = styled('div')`
  display: flex;
  gap: 30px;
`
