import React, { useEffect, useState } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  CardContent
} from '@mui/material'
import { getParams } from '../../../helpers/urlParams'
import { routePaths } from '../../../constants/routes'
import Header from '../../common/Header'
import { useProfileContext } from '../../../contexts/profileContext'
import { buildImageLink, formatPrice } from '../../../helpers'
import {
  Title,
  OrderPrice,
  ProductImage,
  TotalPrice,
  AddressesContainer,
  ButtonContainer,
  Container,
  DeliveryContainer
} from './Styled'
import AddressCard from '../../AddressCard'
import StatusButton from './StatusButton'
import { Address, Order } from '../../../helpers/types'
import StatusTable from '../../StatusTable'
import DeliveryTable from '../../DeliveryTable'
import { Home, Reorder, Settings, ShoppingBag } from '@mui/icons-material'
import { SubContainer } from '../../common/Styled'
import { fetchAdminOrder } from '../../../helpers/api/order'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    information: strapiInformation {
      address
      postalCode
      city
      name
      email
      phoneNumber
      strapi_id
      socialLinks {
        title
        url
        color
        logo
        strapi_id
      }
    }
  }
`

const OrderView = () => {
  const { home, information } = useStaticQuery(query)
  const id = getParams('id')?.id
  const profile = useProfileContext()
  const [order, setOrder] = useState<Order | null>(null)
  const [loading, setLoading] = useState(true)
  const isAdmin = profile?.isAdmin

  const fetchOrder = async () => {
    const result = await fetchAdminOrder(parseInt(id as string, 10))
    setOrder(result)
    setLoading(false)
  }

  useEffect(() => {
    if (isAdmin) fetchOrder()
  }, [isAdmin])

  if (!id || !order || !isAdmin) return null
  const { name, address, postalCode, city, phoneNumber } = information

  const isHomeShipping = order.details.deliveryMode.title !== name

  const { productQuantities, deliveryPrice, totalPrice } = order
  const isHomeDelivery = deliveryPrice > 0

  const title = 'Détails de la commande'

  const localAddress = {
    createdAt: '',
    updatedAt: '',
    address1: address,
    address2: '',
    addressName: name,
    city,
    company: null,
    country: 'France',
    firstName: name,
    id: 1,
    landlinePhone: '',
    lastName: '',
    mobilePhone: phoneNumber,
    postalCode,
    otherInformation: ''
  } as Address

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Settings />,
      label: 'Admin',
      route: routePaths.admin.index
    },
    {
      icon: <ShoppingBag />,
      label: 'Commandes',
      route: routePaths.admin.orders
    },
    {
      icon: <Reorder />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <Header {...{ items, title }} />

      <Container>
        <DeliveryContainer>
          <div style={{ flex: 1 }}>
            <Typography
              variant="h6"
              component="h3"
              style={{ marginBottom: 20 }}
            >
              État de la commande :
            </Typography>
            <StatusTable statusHistory={order.statusHistory} />
          </div>
          <div style={{ flex: 1 }}>
            {isHomeDelivery && (
              <>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ marginBottom: 20 }}
                >
                  Livraison :
                </Typography>
                <DeliveryTable
                  deliveryMode={order.details.deliveryMode}
                  trackCode={order.trackCode}
                  deliveryPrice={deliveryPrice}
                />
              </>
            )}
          </div>
        </DeliveryContainer>
      </Container>

      <Container>
        <Typography variant="h6" component="h3">
          Adresses :
        </Typography>
        <AddressesContainer>
          {isHomeDelivery ? (
            <AddressCard
              addressName={`Adresse de livraison (${order.details.shippingAddress.addressName})`}
              address={order.details.shippingAddress}
            />
          ) : (
            <AddressCard
              addressName="Adresse de récupération"
              address={localAddress}
            />
          )}
          <AddressCard
            addressName={`Adresse de facturation (${order.details.billingAddress.addressName})`}
            address={order.details.billingAddress}
          />
        </AddressesContainer>
      </Container>

      <Container>
        <Typography variant="h6" component="h3">
          Produits :
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: '100px' }}>
                  Produit
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Quantité</TableCell>
                <TableCell align="right">Prix unitaire</TableCell>
                <TableCell align="right">Prix total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.details.products?.map(product => {
                const productQuantity = productQuantities[product.id]
                return (
                  <TableRow key={product.id}>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{ width: '100px' }}
                    >
                      <ProductImage
                        src={buildImageLink(product.images[0].url)}
                        alt={product.images[0].alternativeText}
                      />
                    </TableCell>
                    <TableCell>
                      <Title color="secondary">{product.title}</Title>
                    </TableCell>
                    <TableCell align="center">{productQuantity}</TableCell>
                    <TableCell align="right">
                      {product.promotion ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ textDecoration: 'line-through' }}
                          >
                            {formatPrice(product.price)}
                          </Typography>
                          <Typography variant="body2">
                            {formatPrice(product.promotion)}
                          </Typography>
                        </>
                      ) : (
                        formatPrice(product.price)
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <OrderPrice>
                        {formatPrice(
                          (product.promotion || product.price) * productQuantity
                        )}
                      </OrderPrice>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell>
                  <OrderPrice>{`Livraison (${order.details.deliveryMode.title})`}</OrderPrice>
                </TableCell>
                <TableCell align="right">
                  <OrderPrice>
                    {deliveryPrice === 0
                      ? 'Gratuit !'
                      : formatPrice(deliveryPrice)}
                  </OrderPrice>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell>
                  <TotalPrice>Total</TotalPrice>
                </TableCell>
                <TableCell align="right">
                  <TotalPrice>
                    {formatPrice(totalPrice + deliveryPrice)}
                  </TotalPrice>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Container>
        <Typography variant="h6" component="h3">
          Commentaire :
        </Typography>
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            <Typography>{order.comment}</Typography>
          </CardContent>
        </Card>
      </Container>
      <ButtonContainer>
        <StatusButton
          refetchOrder={fetchOrder}
          {...{ isHomeShipping, loading, order }}
        />
      </ButtonContainer>
    </SubContainer>
  )
}

export default OrderView
