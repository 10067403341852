import React from 'react'
import OrderView from '../../components/Admin/Order'
import SEO from '../../components/common/seo'

const Order = () => (
  <>
    <SEO title="Commande" />
    <OrderView />
  </>
)

export default Order
